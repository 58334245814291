.StripeElement {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: white;
  // box-shadow: 0 1px 3px 0 #e6ebf1;
  // -webkit-transition: box-shadow 150ms ease;
  // transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  // box-shadow: 0 1px 3px 0 #cfd7df;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}