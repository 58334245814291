//-----------------------------
// GLOBAL STYLING
//-----------------------------

.btn {
    border-radius: 3rem;
    font-family: $font-main-heading;
}

.btn-lg {
    font-size: 1rem;
    padding: 0.5rem 2rem;
}

.btn-primary {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;
    
    &:hover {
        background-color: darken($color-primary, 10%);
        border-color: darken($color-primary, 10%);
    }
}

.btn-secondary {
    background-color: transparent;
    color: $color-primary;
    border-color: $color-primary;
    min-width: 80px;

    &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.btn-white-background {
    
    &:hover {
        background-color: darken($color-primary, 10%) !important;
    }
}

.btn-dark {
    background-color: $color-dark-grey;
    color: $color-white;
    margin-left: 4px;
    padding: 5px;
    &:hover {
        color: $color-white;
    }
}

.page-title {
    margin-bottom: 0;
    // text-transform: uppercase;
    // text-align: center;
}


.prod-read-more {
    padding-left: 1rem;
}

.card-container-alt {
    background-color: #fff;
}

.account-card {
    margin-bottom: 0;
}

.progress {
    border-radius: 0;
    height: 1.5rem;
    .progress-bar {
        background-color: #32CD32;
    }
}

// .col-md-4, .col-md-8, .col-md-12 {
//     padding-right: 0;
//     padding-left: 0;
// }

.main-content {
    // display: flex;
    // flex-wrap: wrap;
}


//-----------------------------
// REGISTRATION FORMS
//-----------------------------

.registration-form-submit {
    font-size: 20px;
    margin-top: 1rem;
    padding: 0.5rem 2rem;
}


.multi-choice-label {
    font-size: 20px;
}

//-----------------------------
// CONTAINER SIZE
//-----------------------------
@media (min-width: 768px){
    .container {
        max-width: 960px;
    }
}