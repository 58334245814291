.card {
  border-radius: 0.5rem;
}

.card-image {
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.prod-desc {
  padding: 1rem 1rem 0 1rem;
}

.prod-summary {
  padding: 1rem;
}

.card--task {
  display: block;
  
  .card-figure {
    margin-bottom: 0;
  }

  .prod-summary {
    display: flex;
    padding: 2rem 1.5rem;

    .fa-check-circle {
      color: $color-med-grey;
      font-size: 3rem;
      margin-right: 1rem;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.card--task-complete {
  .prod-summary {
    background-color: $color-green;
    color: $color-white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .fa-check-circle {
      color: $color-white;
    }
  }
}

.card-progress-bar {
  border-radius: 3px;
  background-color: $color-secondary;
  margin: 0 1rem;

  p {
    padding: 0.5rem;
    color: #fff;
    margin-bottom: 0;
  }
}

.card--horizontal {
  margin-bottom: 2rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

  .card-image-link {
    display: block;
    height: 100%;
  }

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-date-col {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      border-left: 1px solid $color-lightest-grey;
    }
  }

  .card-date {
    text-align: center;
    border: 1px solid $color-light-grey;
    border-radius: 4px;
  }

  .card-date__month {
    border-bottom: 1px solid $color-light-grey;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2;
  }

  .card-date__day {
    font-size: 2rem;
    font-weight: bold;
  }

  .card-day {
    display: flex;
    font-size: 0.9rem;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0;
    }

    .card-day__date {
      margin-right: 1.5rem;
      text-align: center;
      font-weight: bold;
      font-size: 0.8rem;
      text-transform: uppercase;
    }

    .card-day__day {
      margin-top: 0.2rem;
    }
  
    .card-day__number {
      font-size: 1rem;
    }
  
    .card-day__quest-title {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0.1rem;
    }
  
    .card-day__time {
      font-size: 0.8rem;
    }
  }

  .card-starts{
    display: flex;
    margin-bottom: 2rem;
  }

  .card-starts__starts {
    margin-right: 1.5rem;
    margin-top: 0.1rem;
  }

  .card-starts__time {
    display: flex;
  }

  .card-starts__unit {
    margin-right: 1.5rem;

    p {
      margin-bottom: 0;
    }
  }

  .card-starts__unit-number {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .card-starts__unit-name {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
}