.header-desktop {
    @media (max-width:768px) {
        display: none;
    }
    .header-top{
        background-color: $color-dark-grey;
        position: relative;

        // .o-layout--home & {
        //     position: absolute;
        //     z-index: 1;
        //     width: 100%;
        //     background-color: rgba($color-black, 0.3);
        // }

        .header-top-container {
            display: flex;
            flex-wrap: wrap;

            .header-logo {
                width: 50%;

                a {
                    color: #fff;
                    padding-left: 0;
                }
                
                .o-layout--home & {
                    display: none;
                }
            }

            .header-top-list{
                width: 50%;
                position: relative;
                display: flex;
                list-style-type: none;
                margin-bottom: 0;
                justify-content: flex-end;

                .o-layout--home & {
                    width: 100%;
                    justify-content: center;
                }

                li {
                    color: #fff;

                    &:last-child {
                        a {
                            padding-right: 0;
                        }
                    }
                    
                    a {
                        color: #fff;
                    }
                }

                .register-dropdown {
                    div {
                        position: absolute;
                        top:40px;
                        right:0;
                    }

                    &:hover .dropdown-list {
                        display: block;
                    }
                }
            }
        }
    }
}


.dropdown-list {
    display: none;
    background-color: darken($color-primary, 15%);
    box-shadow:0 0 2px 0 rgba(0,0,0,.2);
    z-index: 1000;
    min-width: 250px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    ul {
        list-style-type: none;
        z-index: 1002;

        li{
            margin-bottom: 1px;
        
            &:last-child {
                padding-top: 0 !important;
                padding-right: 0!important;
            }
        
            &:hover {
                background-color: darken($color-primary, 10%);
            }
        
            a {
                color: $color-white;
                font-weight: 400;
            }
        }
    }
}

// .navbar {
//     z-index: 1;
//     background-color: $color-primary;
//     box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);

//     .navbar-brand img {
//         height: 120px;
//     }
    
//     .navbar-nav {
//         margin-left: auto;
//         margin-right:0 !important;        
//     }

//     .nav-link {
//         background-color: darken($color-primary, 5%);
//         padding: 1rem 1.5rem !important;
//         margin-left: 0.5rem;
//     }
// }   

header {
    padding-bottom: 56px;
}

.navbar {
    background-color: $color-dark-grey;
}

@include media-breakpoint-up(md) {
    .nav-link {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

.o-layout--home {
    .navbar-brand {
        display: none;
    }

    .navbar-nav {
        margin-right: auto;
    }
}

@media only screen and (min-width:768px) {
    .header-mobile {
        display: none;
    }
}

.header-mobile {
    
    .logo-container-mobile {
        .navbar-brand {
            img {
                height: 250px;
            }
        }
    }
    .hamburger {
        padding-right: 60px;
        svg, i{
            width: 5rem;
            height: 5rem;
        }
    }
    .mobile-menu {
        position: absolute;
        top: 260px;
        left: 0;
        z-index: 1000;
        width: 100vw;
        height: calc(100vh - 260px);
        background-color: $color-primary;
        ul {
            list-style-type: none;
            z-index: 1002;
            li{
                background-color: $color-primary;
                &:hover {
                    background-color: darken($color-primary, 10%);
                    a {
                        color: $color-white;
                    }
                }
                &:last-child {
                    padding-top: 0 !important;
                    padding-right: 0!important;
                }
                a { 
                    display: flex;
                    justify-content: space-between;
                    color: $color-white;
                    font-weight: 400;
                    font-size: 5rem;
                    padding-left: 60px;
                    padding-right: 60px;
                    border-bottom: 1px $color-dark-grey solid;
                }
            }
            .register-dropdown {
                .mobile-dropdown-list {
                    ul {
                        li {
                            background-color: $color-dark-grey;
                           a {
                               padding-left: 120px;
                               border-bottom: 1px $color-white solid;
                           }
                        }
                    }
                }
            }
        }
    }
}

.sub-header {
    background-color: $color-lightest-grey;

    .container {
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
        }

        h1 {
            padding: 1rem 0 1rem 0;
        }
        
        .account-buttons {
            padding: 1rem 0 1rem 0;
        }
    } 
}

