.c-tile {
  @include make-col-ready();
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(md) {
    @include make-col(4);
  }

  @include media-breakpoint-up(lg) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}

.c-tile__content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}