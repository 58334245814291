.course-video {
    display: flex;
    justify-content: center;
    background-color: #000;
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.lead-paragraph {
    font-size: 1rem;
}

.normal-paragraph {
    font-size: 0.9rem;
    margin-bottom: 3rem;
}

.chapter-controls {
    max-height: 80px;
    display: flex;
    .chapter-left {
        margin-right: 2.5px
    }
    .chapter-right {
        margin-left: 2.5px
    }
    > a,
    > div {
        display: flex;
        justify-content: center;
        background-color: $color-lightest-grey;
        transform: none;
        height: 80px;
        &:hover {
            background-color: darken($color: $color-lightest-grey, $amount: 10%);
            cursor: pointer;
        }
        .chapter-content {
            align-items: center;
            display: flex;
            p {
                margin-bottom: 0;
                color: $color-med-grey;
                padding: 0 1rem;
            }
        }
    }
    > div:hover {
        background-color: $color-lightest-grey;
        cursor: auto;
    }
}

.c-course-bookmark {
    margin: 0;
    padding: 2rem;
    background-color: $color-lightest-grey;
    
    *:last-child {
        margin-bottom: 0;
    }
}