#about-us {
    padding-bottom: 3rem;
    background-color: $color-lightest-grey;
    .jumbotron {
        background-color: $color-lightest-grey;
        padding: 0;
        margin-bottom: 0;
    }
}

.home-button {
    // margin: 0 auto;
    // width: 135px;
    // display: flex;
}