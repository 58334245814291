.o-table {
  table-layout: fixed;
  width: 100%;
}

.o-table__row:nth-child(odd) {
  background-color: $color-light-grey;
}

.o-table__cell {
  padding: 0.5rem;
}

.o-table__cell--header {
  background-color: $color-med-grey;
  color: $color-white;
}

.o-table__cell--action {
  width: 60px;
}