.form-control::placeholder {
    color: $color-med-grey;
}

.form-errors {
    color: red;
}

.form-success {
    color: $color-green;
}