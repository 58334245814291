.c-inline-nav {
  position: relative;
  background-color: $color-white;
  box-shadow: 0 8px 6px -9px rgba(0,0,0,0.2);

  &.sticky-top {
    z-index: 1030;
    top: 56px;
  }

  .nav-item {
    padding: 0 1rem;
  }

  .nav-link {
    padding: 0.75rem 0;
    border-bottom: 5px solid transparent;
  }

  .active {
    .nav-link {
      border-bottom-color: $color-primary;
    }
  }
}