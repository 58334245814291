html,
body {
    font-family: $font-body;
    color: $color-dark-grey;
    height: 100%;
    font-weight: 300 !important;
}

h1 {
    font-family: $font-main-heading;
    margin-bottom: 1.5rem;
    color: $color-dark-grey;
    font-weight: 700;
}

h2 {
    font-family: $font-sub-heading;
    margin-bottom: 1.5rem;
    color: $color-dark-grey;
    font-weight: 600;
    font-size: 2rem;
}

h3 {
    font-family: $font-main-heading;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: $color-dark-grey;
}

a {
    transition: all 300ms ease-in-out;
    color: $color-dark-grey;

    &:hover {
        text-decoration: none;
        transform: translateY(1px);
        color: darken($color-dark-grey, 10%);
    }
}

ul {
    padding-left: 0;

    li {
        list-style-position: inside;
    }
}

section {
    padding-top: 3rem;
    padding-bottom: 3rem;

    section {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

b {
    font-weight: 600;
}