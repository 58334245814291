.alert {
  ul {
    margin-left: 1rem;

    li {
      list-style-position: outside;
    }
  }

  a {
    color: $primary;
    text-decoration: underline;
  }
}