$color-white: #fff;
$color-black: #2f2f2f;
$color-primary: #2a8cf8;
$color-secondary:  #4fa0ca;
$color-highlight: #eaca06;
$color-green: #11bf89;
$color-dark-grey:  #373A3C;
$color-med-grey:  #999;
$color-light-grey: #ECEEEF;
$color-lightest-grey: #f2f2f2;

$theme-colors: (
    "primary": $color-primary,
    "secondary": $color-primary
);

$link-colour: $color-black;
