.c-accordion {
  .card {
    margin-bottom: 1rem;
  }

  .card-header {
    border-bottom: none;

    .btn {
      width: 100%;
      text-align: left;

      &:after {
        font-family: "Font Awesome 5 Free"; font-weight: 900;
        content: "\f077";
        float: right;
      }

      &.collapsed:after {
        content: "\f078";
      }
    }
  }

  .card-body {
    padding: 0;

    .list-group-item {
      padding: 1.2rem 0;
      margin: 0 2rem;
      margin-top: -1px;
      width: auto;

      > span {
        display: block;
        transition: all 300ms ease-in-out;
      }

      .fa-check {
        color: $color-green;
        float: right;
      }

      &:hover {
        background-color: transparent;
        transform: none;

        span {          
          transform: translateY(1px);
        }
      }

      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      &.active {
        margin: 0;
        margin-top: -2px;
        padding-left: 2rem;
        padding-right: 2rem;

        &:hover {
          background-color: $color-primary;
        }
      }
    }
  }
}