.carousel-item {
    height: 400px;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.carousel-caption {
    // max-width: 300px;
    padding: 20px;
    // background-color: rgba(0, 0, 0, 0.75);
    border-radius: 3px;
    bottom: 75px;

    h1,
    p {
        color: $color-white;
    }

    &:hover {
        p {
            color: $color-white;
        }
    }
}