.survey-container {
    margin: 4rem 0;
    .survey-content {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        background-color: $color-lightest-grey;
        margin-bottom: 1rem;
        border: 1px lightgrey solid;
        border-radius: 3px;
        h5 {
            margin-bottom: 0;
            line-height: 38px;
        }
        .survey-buttons {
            display: flex;
            a {
                margin-right: 0.5rem;
            }
            .survey-amount {
                background-color: $color-secondary;
                color: #fff;
                border-radius: 25px;
                padding: 7px 25px 0;
                min-width: 105px;
                text-align: center;
            }
        } 
    }
}

.target-group {
    margin-top: 4rem;
    .target-group-title {
        padding-left: 15px;
    }
}

.form-ask-question {
    display: flex;
    a {
        min-width: 0;
        width: 48px;
        margin-left: 0.5rem;
        i {
            line-height: 34px;
        }
    }
}