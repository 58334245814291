.u-phone,
.u-website,
.u-address {
    &:before {
        @include fa-icon;
        @extend .fas;
        content: fa-content($fa-var-phone);
        vertical-align: initial;
        margin-right: .5rem;
        font-size: .7rem;
    }
}

.u-website {
    &:before {
        content: fa-content($fa-var-globe);
    }
}

.u-address {
    &:before {
        content: fa-content($fa-var-map-marker-alt);
        margin-right: .7rem;
    }
}

.u-tint {
    background-color: $color-lightest-grey;
}

.u-tint--dark {
    background-color: $color-dark-grey;
    color: $color-white;

    * {
        color: $color-white;
    }
}

.u-primary-colour {
    color: $color-primary;
}

.u-rounded {
    border-radius: 1rem;
}

.u-bg {
    color: $color-white;
    background-size: cover;
    background-position: center center;

    h1,
    h2,
    h3 {
        color: $color-white;
    }
}

.u-bg--top {
    background-position: center -145px;
}

.u-bg--dark-text {
    color: $color-dark-grey;

    h1,
    h2 {
        color: $color-dark-grey;
    }
}

.u-bg-fade {
    position: relative;
    overflow: hidden;
    color: $color-white;
    padding: 0;

    h2 {
        color: $color-white;
    }

    .u-bg-fade__content {
        padding-top: 3rem;
        padding-bottom: 3rem;
        background: linear-gradient(to bottom, rgba(255,255,255,0),rgba(255,255,255,1));
    }
}

.u-padding-xl {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.u-extend-right {
    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }

    > div:last-child {
        @include media-breakpoint-up(lg) {
            flex-basis: auto;
            max-width: none;
            width: calc(66.66667% + ((100vw - 960px) / 2));
            padding-right: 0;

            section & {
                margin-top: -3rem;
                margin-bottom: -3rem;
            }
        }
    }
}

.u-objectfit {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.u-rounded-bg {
    background-color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
}