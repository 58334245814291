.account-subtitle {
    > img {
        width: 100%;
        margin: 2rem 0;
    }

    > h3 {
        margin-bottom: 0;
        padding: 2rem 0 2rem 0;
    }
}

.main-content {
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 4rem;
    
    .sidebar-menu-container {
        .sidebar-menu-list {
            list-style-type: none;
            background-color: $color-lightest-grey;
            border: 1px $color-light-grey solid;
            border-radius: 0.3rem;
            .sidebar-menu-item {
                border-bottom: 1px $color-light-grey solid;
                &:first-child {
                    border-top-left-radius: 0.3rem;
                    border-top-right-radius: 0.3rem;
                }
                &:last-child {
                    border-bottom: none;
                    border-bottom-left-radius: 0.3rem;
                    border-bottom-right-radius: 0.3rem;
                }
                a {
                    padding: 1rem;
                    display: block;
                }
            }
            .is-active {
                background-color: #1C7CD5;
                a {
                    color: #fff;
                }
            }
        }
    }
    .profile-details {
        @include media-breakpoint-up(md) {
            padding-left: 3rem;
        }

        .account-form-group {
            .form-group {
                .account-form-input {
                    height: 46px;
                }
            }
        }
    }
}

.account-content-block {
    margin: 4rem 0;
    .container{
        form {
            max-width: 600px;
        }
    }
}